/* Container */
.report-container{
    padding-left: 0 !important;
    padding-right: 0 !important;

}


/* Modal */

.error-message {
    color:#CF291E;
    font-weight:600;
}

.report-widget{
    width: 97%;
    padding: 15px 30px;
    margin-bottom: 20px;
    border-radius: 0.25rem;
    box-shadow: 0 0.5rem 2rem #dee2e5;
    border:1px solid rgb(200, 204, 209);
    height: 95%
}


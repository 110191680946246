.sidebar{
position: absolute;
bottom: -100;
height: calc(100%);
z-index: 1041;
transform:  translate3d(0, 0, 0);

}

.nav-brand{
    background-color: #34465A  !important;
    padding: 12px 5px 12px 18px;
  }

.all-sidebar-components{
    background-color: #34465A;
    color: rgb(231, 229, 229);
    font-weight:550;

}   
.icon-classes{
    margin-right:15px !important;
    font-size:15px;
    color: #83b0f3
}
.icon-hover{
    color: #fafafa;
}

.menu-header{
    font-size: 17px !important;
    margin-left: 15px;
}
.nav-header{
    margin-top: 15px
}

.side-nav-item{
    font-size: 17px !important;
}

.item-selected{
    color: #fafafa !important;
    background-color: #316DCA !important;
    
}

.item-hovered{
    color:  #2b5281 !important;
    background-color: #27394d !important;
}

:root {
  --primary-blue: #2F6BC8;
  --hover-primary: #3D7BDC;
  --darker-primary: #316DCA;
  --lighter-primary: #4990D9;
}

.new-line{
  white-space: pre-line;
}

/* Scroll bar */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0,0,0,0.1);
}
::-webkit-scrollbar-thumb{
  border-radius: 10px;
  background: rgba(0,0,0,0.2);
}
::-webkit-scrollbar-thumb:hover{
    background: rgba(0,0,0,0.4);
}
::-webkit-scrollbar-thumb:active{
    background: rgba(0,0,0,.5);
}

/*website Layout*/

.main-content{
  padding-left: 250px;
  padding-right: 0px;
  transform: translate3d(0, 0, 0);
  position: relative;
  overflow-y:scroll;
  position:fixed;
  height: 95vh;
  top: 54px;
  padding-top: 10px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%
}


@media (max-width: 992px) {
.main-content{
  padding-left: 20px !important;
}

.top-navbar {
  padding-left: 20px !important;
}
}

.main-page{
  /* box-shadow: 0 0.5rem 2rem #E9E9EA ; */
  background-color: inherit;
  padding-bottom:2%;
  border-radius:10px;
  width:100%;
  margin-top:5px;
  overflow-y: auto;
}
.main-header{
  color:#224166;
  text-align: left;
  font-size: 28px
}
.sidebar-toggled-main-content{
  z-index: 1040;
  background: #000;
  height: 1000vh;
  width: 100vw;
  position:absolute;
  top: 0;
  left: 0;
  margin: 0;
  overflow: hidden;
  opacity: .5;
}

.sidebar-toggled-hide-navbar{
  z-index: 1040;
  background: #000;
  height: 54px;
  width: 100vw;
  margin-left: 0 !important;
  position:absolute;
  float:right;
  top: 0;
  left: 0;
  margin: 0;
  overflow: hidden;
  opacity: .5;
}

/*Top Navbar */
.top-navbar {
  z-index: 1000;
  background-color: #316DCA;
  width: 100%;
  padding-left: 230px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  position: absolute;

}

.collapse-button{
  margin-left: 50px
}
.toggle-button{
  margin-left: 30px
}
@media (max-width: 992px) {
  .sidebar-toggle-button-col{
    text-align: left;
    width: 80px;
  }

}
.sidebar-collapsed-top-nav {
  padding-left: 80px;
  
}
@media (min-width: 992px) {
  .toggle-button{
    display: none;
  }
}
@media (max-width: 992px) {
  .collapse-button{
    display: none;
  }
  .department-select{
    margin-left: 85px  !important;
  }
}
.department-select{
  font-weight: 550;
  text-align: left;
  width: 300px;
  position: absolute;
  margin-left: 100px
}
.user-profile{
  border: 1.5px solid #E0E8F6;
  border-radius: 50%;
  width: 36px;
  height: 35px;
  color: #E0E8F6;
  font-size: 16px;
  cursor: pointer;
}

.user-profile:hover{
  background-color: #285caa;
}

.user-popover{
  width: 240px;
  height: 93px;
}
.delete-row{
  width: 238px;
  margin-left: 0px; 
  padding: 6px 0px ;

}
.delete-row:hover{
  background-color: #edeff1;
}
.user-role-icon-user{
  background-color: #316DCA !important;
  border: 1px solid #2963b9 !important;
}

.user-role-icon-admin{
  background-color: #1852a8 !important;
  border: 1px solid #0f4392 !important;
}

.department-chip-icon{
  color: #f4f4f5 !important;
}
/*Pages */
/*Changes page size based on if the side bar is collapsed */
@media (min-width: 1200px) {
  .page-container-collpased{
    width: 1315px;
  }
}
@media (min-width: 992px) {
  .page-container-collpased{
    width: 1115px;
  }
}

/*Login*/
.login-container {
    background: url('../components/assets/images/documents.webp');
    background-size: 100%;
    background-position: center top;
    position:absolute;
      top:0;
      bottom:0;
      left:0;
      right:0;
      overflow:hidden;
  }

  @media (max-width: 1199px) {
    .login {
      border-radius: 25px;
      padding: 25px;
      margin: auto;
      width: 80%;
      background-color: white;
      box-shadow: 0 0 10px #9a9fa3;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  
    .loginLogo {
      background-image: url('https://machgroup.co.uk/MACH_GROUP.png');
      background-size: contain;
      background-repeat: no-repeat, repeat;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      height: 130px;
      max-width: 300px;
    }
  }
  
  @media (min-width: 1200px) {
    .login {
      border-radius: 25px;
      padding: 25px;
      margin: auto;
      width: 43%;
      background-color: white;
      box-shadow: 0 0 10px #9a9fa3;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  
    .loginLogo {
      background-image: url('https://machgroup.co.uk/MACH_GROUP.png');
      background-size: contain;
      background-repeat: no-repeat, repeat;
      display: block;
      margin-left: auto;
      margin-right: auto;
      text-align: center !important;
      height: 130px;
      max-width: 350px;
    }
  }

  .new-line{
    white-space: pre-line;
  }

  /* Modal */
  .delete-header{
    background-color: #3D5566;
    color:white;
  }
  .modal-delete-text{
    font-weight:500;
    font-size:18px;
  }
  .footer{
    background-color: #DEDEDE;
  }
  .heading{
    background-color: var(--primary-blue);
    color:white;
  }

  .primary-button{
    background-color: var(--primary-blue);
    color: white;
  }
  .primary-button:hover{
    color:white;
    background-color: var(--hover-primary);
  }

  .border-button{
    border: 1px solid var(--darker-primary) !important
  }
  .save-button{
    margin-right:5px;
    width: 78px
  }
  .delete-button{
    width: 90px
  }
  .edit-button{
    margin-right: 5px
  }
  .create-button{
    width: 92px
  }

  .bin-button{
    width: 78px
  }
  .restore-button{
    width: 100px
  }

  .deactivate-button{
    width: 111px
  }


  /*Card / Table View */

  .view-col{
    text-align: left;
    padding-left: 0;
    padding-top: 12px;
    padding-bottom: 8px
  }
  .card-view-button{
    border-right: 2px solid #2757a0;
    padding: 3px 0 0 0 !important;
    width: 83px !important;
  }

  .view-button{
    display: inline-block;
    cursor: pointer;
    margin-left: 0;
    height: 30px;
    padding: 3px 0 0 8px;
    width: 87px;
    text-align: left;
  }

  .MuiTablePagination-selectLabel{
    margin-top: 15px !important
  }
  .MuiTablePagination-displayedRows{
    margin-top: 15px !important
  }
  /* Icons */
.all-font-icons{
  margin: 0;
  padding:0
}
.sidebar-control-icon{
  font-size: 16px;
  margin: 0;
  padding:0
}

/* Standardising Cards */
.card-title{
  font-size: 18px
}
.card-subtitle{
  font-size: 16px
}

/*Search Bar */

.search-bar{
  color: #224166 !important;
  font-size: 16px !important;
  height: 50px !important
}

.search-placeholder{
  color: #224166;
  font-weight: 450;
  font-size: 15px !important
}
.personRemovedUserTable{
    margin:0 0 2px 0;
}

.personRemovedDeleteModal{
    margin: 0 7px 0 0;
}

.users-icon{
    font-size:18px;
    margin-left:-2px;

}

.no-tags-icon{
    margin-right: 8px !important;
}
.admin-chip{
    margin-right: 8px;
    margin-bottom:4px;
    border-radius: 7px !important;
    border: 1px solid #c2c1c1 !important;
    box-shadow: 0 0.1rem 0.5rem #d6d6d8 ;
    color: #2e2f30 !important;
    font-weight: 600 !important;
    background-color: #f4f4f5 !important;
}

.avatar-icon{
    background-color: #68a8e7 !important;
    border: 1px solid #578cc0 !important;
}
.user-initials{
    margin-top: 15px;
    color: #edf2fa;
    font-size: 12px;
    font-weight: 350 !important
}

.avatar-hover{
    background-color: #7ab6f3 !important;
    border: 1px solid #6a9ed3 !important;
}

.department-chip-icon{
    color: #2e2f30 !important;
}

.member-chip{
    margin-right: 8px;
    margin-bottom: 4px;
    border-radius: 7px !important;
    border: 1px solid #c2c1c1!important;
    box-shadow: 0 0.1rem .5rem #d6d6d8 ;
    color: #2e2f30 !important;
    font-weight: 550 !important;
    background-color: #f4f4f5 !important;
}

.chip-hover{
    box-shadow: 0 0 10px #9a9fa3;
}


.role-icon{
    color: #45474C;
    font-size: 20px;
}

.duplicate-warning{
    margin-top:10px;
    font-weight:600;
    color:red;
    font-size:17px;
}

.department-widget{
        width: 97%;
        padding: 15px 30px;
        margin-bottom: 30px;
        border-radius: 0.25rem;
        box-shadow: 0 0.5rem 2rem #dee2e5;
        border:1px solid rgb(200, 204, 209);
        height: 95%
}
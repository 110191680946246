.category-header{
    color:white;
    background-color: #B528F3;
}
.category-card{

    border-radius:8px;
    margin-bottom:20px;
    border: solid 2px #6d97e0;
    background-color: #fdfdfd !important;
    color: #0d44b3 !important;
    min-height: 150px;
    box-shadow: 0 0.2rem 0.8rem #c4bfbf ;
    padding-bottom: 5px; 

}

.category-card:hover{
    box-shadow: 0 0.4rem 1.1rem #a0a0a0 ;
}
.warning-delete{
    font-weight:600;
    color:red;
    font-size:18px;
}
.delete-modal-header{
    color:white;
}
.success-delete{
    font-weight:600;
    font-size:16px;
}

.modal-container{
    box-shadow: 0 0.5rem 2rem #E3E3E3 ;
    padding-top:4%;
    padding-bottom:2%;
    border-radius: 10px;
}

.striped{
    border-bottom: 1px solid #dee2e6 !important;
}
.footer-style{
    font-size: 20px;
    color: white;
    border-top: 1px solid #E7E7E7;
    text-align: center;
    padding: 20px 20px 30px 20px;
    position: relative;
    left: 0;
    bottom: 0;
    height: 60px;
    width: 100%;
    margin-top:5%
  };

  .phantom-style{
    display: block;
    padding: 20px;
    height: 60px;
    width: 100%;
  };

.department-text{
    margin: 5px !important;
   
}

.manage-link{
  color: #316DCA
}

.manage-link:hover{
  color: #234d8d
}


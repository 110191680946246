.section-header{
    background-color: #306FED;
    color:white;
}

.row-1{
    margin-bottom:2%;
}

.no-category{
    font-size: 15px;
    font-weight: 550;
    color: red;
}
.section{
    border-radius:8px;
    margin-bottom:20px;
    border: solid 2px #6484c0;
    background-color: #fdfdfd !important;
    color: #0d44b3 !important;
    min-height: 160px;
    box-shadow: 0 0.2rem 0.8rem #c4bfbf ;
    padding-bottom: 5px;
    cursor: pointer
}

.section:hover{
    box-shadow: 0 0.4rem 1.1rem #a0a0a0 ;
}

/*Tagify */

.tags-look .tagify__dropdown__item{
    display: inline-block;
    border-radius: 3px;
    padding: .3em .5em;
    border: 2px solid #337ff1;
    background-color: #3c86f5 !important;
    margin: .2em;
    font-size: .85em;
    color: #f3f3f3 !important;
    transition: 0s;
    font-weight:550;
    
  }
  
  .tags-look .tagify__dropdown__item--active{
    color: black;

  }
  
  .tags-look .tagify__dropdown__item:hover{
    background-color: #2a74e2 !important;

  }


 .tagify{

    --placeholder-color-focus: blue;
   } 
  .tag-selector .tagify__tag > div{
    --tag-bg                  : #3c86f5;
    --tag-hover               : #2a74e2;
    --tag-text-color          : #FFF;
    --tags-border-color       : silver;
    --tag-text-color--edit    : #111;
    --tag-remove-bg           : var(--tag-hover);
    --tag-pad                 : .3em 0.8em;
    --tag-inset-shadow-size   : 1.35em;
    --tag-remove-btn-bg--hover: black;
    --tag-remove-bg: #6ea6fa;


  
    display: inline-block;
    min-width: 0;
    border: none;
  }
  .tag-selector{
      text-align: left !important;
      border-bottom: 1px solid #a1a7af !important;
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
      border-radius: 3px !important;
      color: #224166 !important;
      padding: 5px !important;
      font-size: 14px !important;
      background-color: #F0F0F0 !important;
      width: 100%;

  }
.section-widget{
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    background-color: rgb(251, 252, 253);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0 0.5rem 2rem #cacbcc;
    border:1px solid rgb(200, 204, 209)
}

.section-widget-header{
    background-color: #185ec9;
    color:white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding-bottom:5px;    
    padding-top:3px;
    border:2px solid #3265b1;
    z-index: 1
}
.category-select{
    height: 56px;
    text-align: left;
}

.category-option{
    font-size: 14px !important;
}

.category-button{
    color: #09326e !important;
    padding-right: 40px !important;
    padding-left: 40px !important;
    font-weight: 550 !important
}
.category-button-chosen{
    color: #2867c7 !important;
    padding-right: 40px !important;
    padding-left: 40px !important;
    font-weight: 550 !important;
    background-color: #dde9fa !important;
}

@media (max-width: 950px) {
    .category-button{
        padding-right: 10px !important;
        padding-left: 10px !important; 
        font-size: 13px !important;
    }
}
.revision-card{
    border-radius:10px;
    margin-bottom:3px;
    border-width:2px;
    border-color: #d1d0d0;
}

.revision-card:hover{
    background-color: #e6eefc ;
    box-shadow: 0 0.2rem 1rem #d4d3d3 ;
}
.revision-card-selected{
    background-color: #bcd2f8;
    border-width:4px;
    border-color: #9ebff8;
    border-radius:10px;
    margin-bottom:3px;
    box-shadow: 0 0.3rem 1rem #c4bfbf ;
}

.revision-title{
    font-size: 17px !important;
}

.revision-changes{
    font-size: 16px !important;
    color: #3c3c3d;
    margin-bottom: 5px;
}

.revision-author{
    font-weight: 550;
    font-size: 14px;
}


/* Bin */
.deleted-section-card{
    border-radius:8px;
    margin-bottom:20px;
    border: solid 2px #6d97e0;
    background-color: #fdfdfd !important;
    color: #0d44b3 !important;
    min-height: 150px;
    box-shadow: 0 0.2rem 0.8rem #c4bfbf ;
    padding-bottom: 5px;
    cursor: pointer;
}

.deleted-section-card:hover{
    box-shadow: 0 0.4rem 1.1rem #a0a0a0 ;
}

.clicked-deleted-section-card{
    border-radius:8px;
    margin-bottom:20px;
    border: solid 2px #598be2;
    background-color: #c4d8fa !important;
    color: #0d44b3 !important;
    min-height: 150px;
    box-shadow: 0 0.5rem 1.5rem #818080 ;
    padding-bottom: 5px;
    cursor: pointer;
}

.deleted-revision-card{
    border-radius:10px;
    margin-bottom:3px;
    margin-right: 8px;
    border-width:2px;
    border: solid 2px #598be2;
    background-color: #f3f6fa;
    height: 50px;
    width:160px;
    color: #0d44b3 !important;
    display: inline-block;
    cursor: grab;
}

.deleted-revision-card:hover{
    box-shadow: 0 0.1rem 0.5rem #d1d1d1 ;
}

.deleted-revision-card-selected{
    border-radius:10px;
    margin-bottom:3px;
    margin-right: 8px;
    border-width:2px;
    border:solid 2px #3a66b3;
    margin-right: 5px;
    background-color: #b2cff5;
    box-shadow: 0 0.1rem 0.5rem #b9b9b9 ;
    height: 50px;
    width:160px;
    color: #0d44b3 !important;
    display: inline-block;
    cursor: grab;

}

.deleted-section-revisions-box{
    white-space: nowrap;
    height: 63px;
    cursor: grab;
    overflow-y: hidden !important;
    overflow-x: auto !important;
}


  

  
.tag-header{
    background-color: #28E23B;
    color:white;
}
.tag-card{
    border-radius:8px;
    margin-bottom:20px;
    border: solid 2px #8fb3f5;
    background-color: #b8cef7 !important;
    color: #0d44b3 !important;
    min-height: 110px;
}
.tag-card:hover{
    background-color: #a4c0f5 !important;
    box-shadow: 0 0.2rem 1rem #d4d3d3 ;
}
.icon-tag{
    margin-top:20px
}
.tag-updated-by{
    color: #4c6797;
    margin-top: 0px;
}
.tag-updated{
    font-size: 10px;
}

.Deactivated{
    color:#DA3737;
    font-weight:bolder
}

.Active{
    color:#38BB1B;
    font-weight:bolder
}

.admin{
    color:#38BB1B;
}
.user{
    
}

.admin-tabs{
    padding-top:1%;
}
.section-guide{
    font-size: 12px;
}

.view-button{
    margin-left: 2%;
    margin-bottom: 0.5%;
    padding: 1% 2%;
}

.category-buttons{
    margin:0 1% 0 0;
}
.xl-button{
    font-weight:bolder
}
.main-container{
    box-shadow: 0 0.5rem 2rem #E9E9EA ;
    background-color: #FDFDFD;
    padding-bottom:2%;
    border-radius:10px;
    width:80%;
}
.alert-container{
    padding-bottom:0;
    margin-top:0;
    margin-left: 0 !important;
    margin-right: 0 !important;
    width:100% !important;
}


.drag-container{
    margin-top:4%;
    border-radius:15px;
    background-color: #FCFCFC;
}

.category-col{
    margin: 1% 0;
}

/* Rows */
.row-header{
    padding-bottom:0;
    padding-top: 5px;
}



/* Preview Widgets */
.select-section-text{
    font-size:13px;
    color: "#5e5d5d"
}
.template-sections-select{
    font-weight: 450;
    text-align: left;
    font-size: 14px;
    width: 300px;
}

.open-section-button{
    margin-left: 3px;
    margin-top: 2px;
    height:35px !important;
}
.open-section-icon{
    font-size: 20px !important;
    margin-left: -3px;
    margin-top: -5px !important

}
/* Widgets */
.buttons{
    text-align:right;
}
.template-widget{
    border-radius:10px;
    box-shadow: 0 0.5rem 2rem #E3E3E3 ;
    padding-bottom:3%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    border-radius:5px;
    text-align:left;
    background-color: #FCFCFC;
    border:1px solid rgb(200, 204, 209)

}
.template-widget-header{
    background-color: #185ec9;
    color:white;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    padding-bottom:5px;    
    padding-top:3px;
    border:2px solid #3265b1;
    z-index: 1
}
.widget-header-text{
    padding-top:5px;
    text-align:center;
    font-size: 21px
}

.form-group{
    margin-top:2%;
}

.template-form-col{
    margin-top:2%
}

.section-col-header{
    text-align: center;
    margin-bottom:0;
    font-weight:400;
}

/* Cards */

.section-card{
    border-radius:10px;
    margin-bottom:1%;
    border-width:2px;
    padding-right: 10px;
    border-color: #dfdfdf;
}

.section-card:hover{
    background-color: #e6eefc ;
    box-shadow: 0 0.2rem 1rem #d4d3d3 ;
}
.section-card-drag{
    background-color: #bcd2f8;
    border-width:4px;
    border-color: #9ebff8;
    border-radius:10px;
    margin-bottom:1%;
    box-shadow: 0 0.3rem 1rem #c4bfbf ;
    padding-right: 10px;
}
.number-icon{
    background-color:#E0E8F6;
    border-radius: 100%;
    text-align:center;
    font-weight:800;
}
.icon-card-body{
    margin-top:22px;
    margin-bottom:0;
}

.number{
    font-size:15px;
    font-weight: 700;
    color:#3B4F73;
}
.icon{
    color:#3B4F73;
}
.dot {
    height: 35px;
    width: 35px;
    background-color: rgb(240, 238, 238);
    border-radius: 50%;
    display: inline-block;
    font-size:20px;
    font-weight: 700;
    color:#3B4F73;
    text-align:center;
    border: 3.5px solid #3B4F73;
  }

.chip-tags{
    background-color: #A4BCE9 !important;
    color: #325CAD !important;
    font-weight: 600;
    border-radius: 4px !important;
    height:20px !important;
    margin-right:1%; 
    font-size:12px !important;
    padding: right 0.5px !important;
    padding-left:0.5px !important;
}

.label{    
    font-weight:500;
    color:black;

}

.description-area{
    min-height: 35px;
}

.save-icon{
    margin-top: 5px;
}
.feedback{
    color:#FC756C;
    font-weight:500;
    font-size:13px;
}
.user-header{
    color:white;
    background-color: #F32828;
}

.card-container {
	background-color: white;
	height: auto;
	border-radius: 14px;
    box-shadow: 0 0.5rem 2rem #c0c2c4;
    margin-bottom: 20px;
	cursor: pointer;
}
.card-container:hover{
    box-shadow: 0 0.5rem 2rem #8f8f8f;
}
.user-card-header-admin {
	background-image: url("https://machgroup.co.uk/MALogo_Darkerr.png");
    background-size: 320px 200px !important;
	background-position: -10px -40px;
	background-repeat: no-repeat;
	background-size: contain;
	text-align: center;
	border-top-left-radius: 14px;
	border-top-right-radius: 14px;
    height: 110px;
}
.user-card-header-user {
	background-image: url("https://machgroup.co.uk/MALogo_Darker.png");
    background-size: 320px 200px !important;
	background-position: -10px -40px;
	background-repeat: no-repeat;
	background-size: contain;
	text-align: center;
	border-top-left-radius: 14px;
	border-top-right-radius: 14px;
    height: 110px;
}

.icon-user-card-admin{
	margin: auto;
	width: 100px;
    height:100px;
	border: solid white 4px;
    background-color: #1852a8;
	border-radius: 50%;
	position: absolute;
	left: 0;
	right: 0;
	text-align: center;
	top: 55px;
}

.icon-user-card-user{
	margin: auto;
	width: 100px;
    height:100px;
	border: solid white 4px;
    background-color: #316DCA;
	border-radius: 50%;
	position: absolute;
	left: 0;
	right: 0;
	text-align: center;
	top: 55px;
}

.user-icon-logo{
    color: white;
    font-size: 40px;
    margin-top: 25px !important;
}

.user-icon-admin{
    color: white;
    font-size: 40px;
    margin-top: 25px !important;
    margin-left: 5px !important;
}

.bold-text {
	font-weight: bold;
	font-size: 1.1rem;
	text-align: center;
	padding: 10px 20px 0px 20px;
}

.normal-text {
	font-weight: normal;
	font-size: 0.95rem;
	color: hsl(0, 0%, 50%);
	text-align: center;
	padding-bottom: 10px;
}

/* SOCIAL STATS */
.smaller-text {
	font-weight: normal;
	font-size: 0.7rem;
	color: hsl(0, 0%, 50%);
	text-align: center;
	letter-spacing: 1px;
	padding-bottom: 18px;
	line-height: 10px;
}
.deactivated-text{
	font-weight: 550;
	font-size: 15px;
	color: hsl(0, 69%, 53%);
	text-align: center;
	letter-spacing: 1px;
	margin: 0 !important;
}

.social-container {
	display: flex;
	border-top: solid rgb(206, 206, 206) 1px;
	text-align: center;
}

.followers {
	flex: 1;
}

.likes {
	flex: 1;
}

.photos {
	flex: 1;
}